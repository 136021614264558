import axios from "axios";
import store from '../../store/store';
import Vue from 'vue';

//const baseDomain = "https://sod-gateway-api-staging-sod-gateway-api.azurewebsites.net";
const baseDomain = "https://api.scrumteamondemand.com";
const baseURL = `${baseDomain}`;

// ALL DEFUALT CONFIGURATION HERE

const httpReq = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json"
  }
});

httpReq.CancelToken = axios.CancelToken;
httpReq.isCancel = axios.isCancel;

httpReq.interceptors.request.use(
  (config) => {
    let token = store.getters["auth/authToken"];

    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`
    }

    config.headers['Access-Control-Allow-Origin'] = 'http://main.scrumteamondemand.com/';
    config.headers['Access-Control-Allow-Methods'] = 'GET, PUT, POST, DELETE, OPTIONS';
    config.headers['Access-Control-Allow-Credentials'] = 'true';
    return config
  },

  (error) => {
    return Promise.reject(error)
  }
)

httpReq.interceptors.response.use(
  (response) => {
    return response
  }, 

  (error) => {
    if (error.response.status === 401) {
      store.dispatch('auth/authLogout');
      Vue.prototype.stopSignalR();
      console.log('was signed off!')
    }

    return Promise.reject(error)
  }
)

export default httpReq;